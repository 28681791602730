/* eslint-disable @nx/enforce-module-boundaries */
import { Card, HStack, IconButton, Text, VStack } from '@mybridge/ui';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SpotlightBox from 'v4/snippets/campaign/component/SpotlightBox';
import { getDashboardSpotlights } from 'v4/store/actions/campaign.action';

const SponsoredSpotlights = ({location}) => {
  const dispatch = useDispatch();

  const { dashboardSpotlightData, loading } = useSelector(
    (state) => state.campaignReducer
  );

  useEffect(() => {
    dispatch(getDashboardSpotlights({ location:location}));
  }, []);

  return (
    <>
      {dashboardSpotlightData?.length ? (
        <Card
          p={3}
          w="100%"
          //   variant="thinBorderedCard"
          mb={7}
          marginLeft="auto"
          marginRight="auto"
          pos="relative"
          bg="transparent"
          boxShadow="none"
        >
          <Text fontSize="18px" color="brandPrimary.500" mb={3}>
            Sponsored
          </Text>
          <VStack paddingBottom="100px" gap={3} alignItems="flex-start">
            {dashboardSpotlightData?.map((spotlight, i) => (
              <SpotlightBox key={i} campaignDetails={spotlight} isDashboard />
            ))}
          </VStack>
        </Card>
      ) : (
        ''
      )}
    </>
  );
};

export default SponsoredSpotlights;
